/* Layouts */

.navbar {
  &.fixed-top {
    + .page-body-wrapper {
      padding-top: $navbar-height;
    }
  }
}


// Sidebar Mini
.sidebar-mini {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-mini;
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-mini});
      }
    }
    .sidebar {
      width: $sidebar-width-mini;
      .nav {
        .nav-item {
          .nav-link {
            display: block;
            text-align: center;
            position: relative;
            .menu-title {
              display: inline-block;
            }
            .badge {
              margin-left: 5px;
              display: none;
            }
            i {
              &.menu-icon {
                display:block;
                margin-right: auto;
              }
            }
            &[data-toggle="collapse"] {
              .menu-title {
                &:after {
                  content: "\e604";
                  font-family: 'simple-line-icons';
                  speak: none;
                  font-style: normal;
                  font-weight: normal;
                  font-variant: normal;
                  text-transform: none;
                  line-height: 1;
                  -webkit-font-smoothing: antialiased;
                  font-size: 10px;
                  margin-left: 10px;
                }
              }
              &[aria-expanded="true"] {
                .menu-title {
                  &:after {
                    content: "\e607";
                  }
                }
              }
            }
          }
          &.nav-profile {
            .nav-link {
              @include justify-content(center);
              .profile-name {
                display: none;
              }
            }
            i {
              margin-left: 1rem;
            }
          }
          &.nav-progress {
            display: none;
          }
        }
      }
    }

    .content-wrapper,
    .footer {
      margin-left: $sidebar-width-mini;
      width: calc(100% - #{$sidebar-width-mini});
      @at-root #{selector-append(".rtl", &)} {
        margin-left: 0;
        margin-right: $sidebar-width-mini;
      }
    }
    &:not(.sidebar-icon-only) {
      //Apply these styles only when sidebar-mini is not collapsed to icon-only mode
      .sidebar {
        .nav {
          &:not(.sub-menu) {
            > .nav-item {
              border-top: 1px solid rgba($sidebar-dark-menu-color, .2);
              &.nav-doc,
              &.nav-profile {
                border-top: 0;
              }
              .nav-link {
                height: auto;
                padding: $sidebar-mini-menu-padding;
                i {
                  &.menu-icon {
                    margin-bottom: .5rem;
                  }
                }
              }
            }
          }
          &.sub-menu {
            padding: 0 .5rem 0 0.5rem;
            .nav-item {
              .nav-link {
                padding: 1rem 0;
                text-align: center;
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        width: $sidebar-width-icon;
        .brand-logo {
          display: none;
        }
        .brand-logo-mini {
          display: inline-block;
        }
      }
      .navbar-menu-wrapper {
        width: calc(100% - #{$sidebar-width-icon});
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      .nav {
        overflow: visible;
        .nav-item {
          position: relative;
          .nav-link {
            display: block;
            text-align: center;
            .menu-title,
            .badge {
              display: none;
            }
            i {
              &.menu-icon {
                margin-right: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
          }
          &.active {
            background: $sidebar-light-menu-hover-bg;
            @at-root #{selector-append(".sidebar-dark", &)} {
              background: $sidebar-dark-menu-hover-bg;
            }
          }
          &.nav-profile,
          &.nav-progress {
            display: none;
          }
          &.nav-doc {
            margin: 0;
            i {
              display: block;
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
              .nav-link {
                .menu-title {
                  @include display-flex;
                  @include align-items(center);
                  background: linear-gradient(88deg, #13b4ca, #18cabe);
                  color: $white;
                  @at-root #{selector-append(".sidebar-dark", &)} {
                      background: $sidebar-dark-menu-hover-bg;
                  }
                  padding: 0.5rem 1.25rem;
                  left: $sidebar-width-icon;
                  position: absolute;
                  text-align: left;
                  top: 0;
                  width: $sidebar-icon-only-submenu-width;
                  z-index: 1;
                  height: $nav-link-height;
                  line-height: 1.8;
                  border-top-right-radius: 5px;
                  border-bottom-right-radius: 5px;
                  @at-root #{selector-append(".rtl", &)} {
                      left: auto;
                      right: $sidebar-width-icon;
                      text-align: right;
                      border-top-right-radius: 0px;
                      border-bottom-right-radius: 0px;
                      border-top-left-radius: 5px;
                      border-bottom-left-radius: 5px;
                  }
                  &:after {
                    display: none;
                  }
                }
                &[data-toggle=collapse] {
                  .menu-title {
                    border-bottom-right-radius: 0;
                    @at-root #{selector-append(".rtl", &)} {
                      border-bottom-left-radius: 0;
                    }
                  }
                }
              }
              .collapse,
              .collapsing {
                display: block;
                padding: .5rem 0;
                background: $blue-teal-gradient;
                @at-root #{selector-append(".sidebar-dark", &)} {
                    background: $sidebar-dark-bg;
                }
                position: absolute;
                top: $nav-link-height;
                left: $sidebar-width-icon;
                width: $sidebar-icon-only-submenu-width;
                border-bottom-right-radius: 5px;
                @at-root #{selector-append(".rtl", &)} {
                  border-bottom-right-radius: 0;
                  border-bottom-left-radius: 5px;
                }
                @at-root #{selector-append(".rtl", &)} {
                    left: auto;
                    right: $sidebar-width-icon;
                }
                -webkit-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
                -moz-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
                box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
              }
            }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              color: $white;
              &:hover {
                color: darken($white, 20%);
              }
              @at-root #{selector-append(".rtl", &)} {
                text-align: right;
              }
            }
            &:nth-child(5n+1),
            &:nth-child(5n+2),
            &:nth-child(5n+3),
            &:nth-child(5n+4),
            &:nth-child(5n+5) {
              .nav-link {
                &:before {
                  background: $white;
                }
              }
            }
          }
        }
      }
    }

    .content-wrapper,
    .footer {
      margin-left: $sidebar-width-icon;
      width: calc(100% - #{$sidebar-width-icon});
      @at-root #{selector-append(".rtl", &)} {
        margin-left: 0;
        margin-right: $sidebar-width-icon;
      }
    }
  }
}

// Hidden Sidebar
.sidebar-hidden {
  @media (min-width: 992px) {
    .sidebar {
      transition: width $action-transition-duration $action-transition-timing-function;
      -webkit-transition: width $action-transition-duration $action-transition-timing-function;
      -moz-transition: width $action-transition-duration $action-transition-timing-function;
      -ms-transition: width $action-transition-duration $action-transition-timing-function;
      width: 0;
    }

    .content-wrapper,
    .footer {
      margin-left: 0;
      width: 100%;
      @at-root #{selector-append(".rtl", &)} {
        margin-right: 0;
      }
    }
  }
}

// Absolute sidebar with overlay to content
.sidebar-absolute {
  @media (min-width: 992px) {
    &:not(.sidebar-hidden) {
      .sidebar {
        -webkit-box-shadow: 0px 0px 3px 1px #a7a3a3;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow:    0px 0px 3px 1px #a7a3a3;  /* Firefox 3.5 - 3.6 */
        box-shadow:         0px 0px 3px 1px #a7a3a3;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
      }
    }

    .content-wrapper,
    .footer {
      margin-left: 0;
      width: 100%;
      @at-root #{selector-append(".rtl", &)} {
        margin-right: 0;
      }
    }
  }
}

//Horizontal Menu
.horizontal-menu {
  @media (min-width: 992px) {
    .navbar {
      .navbar-brand-wrapper {
        border-bottom: 1px solid $border-color;
      }
    }
    .navbar-toggler {
      &[data-toggle="minimize"],
      &[data-toggle="toggleSidebarDisplay"] {
        display: none;
      }
    }

    .content-wrapper,
    .footer {
      margin-left: 0;
      width: 100%;
      @at-root #{selector-append(".rtl", &)} {
        margin-right: 0;
      }
    }

    .content-wrapper {
      padding-top: 6rem;
    }
    .sidebar{
      width: 100%;
      min-height: auto;
      border-top: 1px solid rgba($sidebar-dark-menu-color, .3);
      border-bottom: 1px solid $border-color;
      .nav {
        flex-direction: row;
        margin-bottom: 0;
        overflow: visible;
        padding: $horizontal-menu-padding;
        .nav-item {
          position: relative;
          padding: $horizontal-menu-item-padding;
          .nav-link {
            height: auto;
            .menu-icon {
              font-size: $horizontal-menu-icon-font-size;
              line-height: 1;
            }
            .menu-title {
              font-size: $horizontal-menu-font-size;
              line-height: 1;
            }
          }
          &.mega-menu {
            position: static;            
          }
          &.hover-open {
            .collapse,
            .collapsing {
              display: block;
              background: color(white);
              position: absolute;
              top: 60px;
              left: 0;
              min-width: 100%;
              padding: 0 1rem;
              @at-root #{selector-append(".rtl", &)} {
                  left: auto;
                  right: 0;
              }
              -webkit-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
              -moz-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
              box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
            }
            &.mega-menu {
              .collapse,
              .collapsing {
                width: auto;
                .mega-menu-section {
                  width: auto;
                  margin-left: 15px;
                  margin-right: 15px;
                  float: left;
                  .mega-menu-title {
                    .nav-link {
                      color: $black;
                      font-size: 1rem;
                      line-height: 1;
                    }
                  }
                  .mega-menu-title,
                  .nav-item {
                    .nav-link {
                      padding-left: 0;
                      padding-right: 0;
                    }
                  }
                }
              }
            }
          }
        }
        &.sub-menu {
          padding: 0;
          .nav-item {
            padding: 0;
            .nav-link {
              color: $horizontal-menu-submenu-color;
              padding: $horizontal-menu-submenu-item-padding;
              border-bottom: 1px solid $border-color;
              &:before {
                display: none;
              }
            }
            &:last-child {
              .nav-link {
                border-bottom: 0;
              }
            }
          }
        }
      }
      >.nav {
        >.nav-item {
          >.nav-link {
            border-right: 1px solid rgba($sidebar-dark-menu-color, .3);
            padding: .55rem 1.5rem;
          }
          &:last-child {
            .nav-link {
              border-right: none;
            }
          }
        }
      }
    }
  }
}

//Fixed sidebar
.sidebar-fixed {
  .sidebar {
    @media(min-width: 992px) {
      position: fixed;
    }
    .nav {
      max-height: calc(100vh - #{$navbar-height});
      overflow: auto;
      position: relative;
      &.sub-menu {
        max-height: none;
      }
    }
  }
}

//Boxed layout
.boxed-layout {
  @media (min-width: 992px) {
    .container-scroller {
      background: lighten(color(gray), 50%);
      padding: 0 (100% - $boxed-container-width) / 2;
    }
    .navbar {
      &.fixed-top {
        margin: auto;
        width: $boxed-container-width;
      }
    }
    &.horizontal-menu {
      .sidebar {
        margin: auto;
        width: $boxed-container-width;
      }
      &.horizontal-menu-top {
        .sidebar {
          width: calc(#{$boxed-container-width} - #{$sidebar-width-lg});
        }
      }
    }
  }
}

//RTL layout
.rtl {
  direction: rtl;
  text-align: right;
  .content-wrapper {
    margin-left: 0;
    margin-right: $sidebar-width-lg;
    @media (max-width: 991px) {
      margin-right: 0;
    }
  }
  .sidebar {
    .nav {
      padding-right: 0;
      .nav-item {
        .nav-link {
          i {
            &.menu-icon {
              margin-right: 0;
              margin-left: .5em;
            }
            &.menu-arrow {
              margin-left: 0;
              margin-right: auto;
            }
          }
          .badge {
            margin-left: 0;
            margin-right: auto;
          }
        }
        &.nav-doc {
          .nav-link {
            .menu-title {
              text-align: right;
            }
          }
        }
      }
      &.sub-menu {
        padding: $rtl-sidebar-submenu-padding;
      }
    }
  }
  .navbar {
    .navbar-nav {
      padding-right: 0;
      .navbar-dropdown {
        .dropdown-item {
          p {
            text-align: right;
          }
        }
      }
    }
  }
  .footer {
    margin-left: 0;
    margin-right: $sidebar-width-lg;
    @media (max-width: 991px) {
      margin-right: 0;
    }
  }

  .settings-panel .tab-content .tab-pane .scroll-wrapper,
  ul.chats,
  .product-chart-wrapper,
  .table-responsive,
  .sidebar-fixed .nav {
    &::-webkit-scrollbar {
        width: .5em;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
  /* style for off-canvas menu*/
  @media screen and (max-width: 991px) {
    .row-offcanvas {
      &.row-offcanvas-right {
        left: 0;
        right: auto;

        .sidebar-offcanvas {
          left: -100%; // 12 columns
          right: auto;
        }

        &.active {
          left: 50%; // 6 columns
          right: auto;

          .sidebar-offcanvas {
            left: -50%; // 6 columns
            right: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    //for smaller devices like iphone 5

    .row-offcanvas {
      &.row-offcanvas-right {
        &.active {
          left: 80%;
          right: auto;

          .sidebar-offcanvas {
            left: -75%;
            right: auto;
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    //for smaller devices like iphone 5

    .row-offcanvas {
      &.row-offcanvas-right {
        &.active {
          left: 100%;
          right: auto;

          .sidebar-offcanvas {
            left: -91%;
            right: auto;
          }
        }
      }

      .sidebar-offcanvas {
        width: 91%;
      }
    }
  }  
}
