/* Footer */

.footer {
  background: $footer-bg;
  color: $footer-color;
  margin-left: $sidebar-width-lg;
  padding: 20px 1rem;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  border-top: $border-width solid $border-color;
  font-size: calc(#{$default-font-size} - 0.05rem);
  font-family: $type1-light;
  a {
    color: theme-color(success);
    font-size: inherit;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
}
